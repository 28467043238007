import request from '@/utils/request'

// 订单管理分页物料查询订单
export const getDictByNames = (names) => {
    return request.get('/api/enterprise/employmentSupport/enterpriseDictController/names', {
        params: {
            names
        }
    })
        .then(response => response)
}
