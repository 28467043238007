<template>
  <a-modal
    title="上传打款凭证"
    cancelText="取消"
    okText="确定"
    :visible="visible"
    width="550px"
    :footer="null"
    @cancel="handleCancel"
    class="payment-voucher-dialog"
  >
    <div class="page-title">唯一指定付款账户：</div>
    <div class="transfer-info border-box">
      <div class="label-content">
        <div class="info-label">对公银行账户号：</div>
        <div class="info-label">账户主体：</div>
        <div class="info-label">电话：</div>
        <div class="info-label">开户行信息：</div>
        <div class="info-label">开户行行号：</div>
      </div>
      <div class="value-content">
        <div class="info-value">
          5329 1314 4010 366
          <a-button type="link" @click="copy" style="height: 21px;">复制</a-button>
        </div>
        <div class="info-value">鼎基智能云平台有限公司</div>
        <div class="info-value">0531-23864642</div>
        <div class="info-value">招商银行青岛国际创新园支行</div>
        <div class="info-value">3084 5202 5510</div>
      </div>
    </div>
    <div class="tips" style="color: red; margin-top: 15px">
      温馨提示：因线下打款存在到账时限问题，平台会尽快完成收款及发货，可在上传打卡凭证后，2-3个工作日在待收货订单查看物流信息。
    </div>
  </a-modal>
</template>

<script>
import { handleCopy } from '@/utils/util'

export default {
  name: 'PaymentVoucher',
  data () {
    return {
      visible: false,
      imageUrl: '',
      orderId: ''
    }
  },
  methods: {
    $init (orderId) {
      this.visible = true
    },
    handleCancel () {
      this.visible = false
    },
    copy () {
      handleCopy('532913144010366')
    }
  }
}
</script>

<style lang="less">
.payment-voucher-dialog {
  .clearfix {
    width: 100%;
    padding: 0 25px;
    .bg {
      background: #555555;
      width: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: blue;
    }
  }
  .border-box {
    border: 1px solid #c4c4c4;
    padding: 15px 20px;
  }
  .page-title {
    font-weight: 550;
    font-size: 18px;
    margin-bottom: 18px;
  }
  .transfer-info {
    display: flex;
    align-items: baseline;
    font-weight: 400;
    .label-content {
      width: 120px;
      text-align: right;
      font-weight: 500;
      .info-label {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .value-content {
      flex: 1;
      .info-value {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .copy-btn {
      color: #3d77ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
