<template>
  <div class="order__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      订单管理
    </div>
    <div class="content">
      <div class>
        <a-row style="height: 51px;">
          <a-col :span="6">
            <a-row>
              <a-col :span="5">
                <div class="order_text">订单编号</div>
              </a-col>
              <a-col :span="16">
                <a-input v-model="orderNo" style="width: 220px" placeholder="请输入订单编号" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6">
            <a-row>
              <a-col :span="3">
                <div class="order_text">日期</div>
              </a-col>
              <a-col :span="21">
                <a-range-picker v-model="date" style="width: 220px" value-format="YYYY-MM-DD" />
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="6">
            <a-row>
              <a-col :span="6">
                <div class="order_text">供应商名称</div>
              </a-col>
              <a-col :span="18">
                <a-input v-model="supplierName" style="width: 220px" placeholder="请输入供应商名称" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" style="text-align: right" v-perms="'search'">
            <a-button type @click="handleReset">重置</a-button>
            <a-button type="primary" class="order_btn" @click="$refs.table.initPage()">搜索</a-button>
          </a-col>
        </a-row>
      </div>
      <div class>
        <div class="order_tab">
          <v-tab :tabArr="tabList" v-model="status" @change="handleRefresh" v-perms="'tab'"/>
        </div>
        <p-table
          ref="table"
          :columns="columns"
          :source-data="getTableData"
          :scorll="{ y: 600 }"
          extraHeight="442"
          download
          fileName="订单管理"
        >
          <template slot="headerLeft">
            <a-button type="primary" @click="handleOpenPage" v-perms="'add'">去模板采购</a-button>
          </template>
          <template slot="name" slot-scope="text, record">
            <a-button type="link" @click="$getPerms('details') && getDetail(record)">{{ text }}</a-button>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm
              title="确认签约"
              ok-text="是的"
              cancel-text="取消"
              @confirm="confirm('sign')"
              v-if="record.orderStatus == 1"
              v-perms="'contract'"
            >
              <a href="#" @click="getItem(record)" style="margin-right: 16px">立即签约</a>
            </a-popconfirm>

            <a-popconfirm
              title="确认取消订单吗"
              ok-text="是的"
              cancel-text="取消"
              @confirm="confirm('cancel')"
              v-if="['0', '1'].includes(record.orderStatus)"
              v-perms="'cancel'"
            >
              <a href="#" @click="getItem(record)">取消订单</a>
            </a-popconfirm>
            <a-popconfirm
              title="是否确认收货"
              ok-text="是的"
              cancel-text="取消"
              @confirm="confirm('sure')"
              v-if="record.orderStatus == 6"
              v-perms="'confirm'"
            >
              <a-button type="link" @click="getItem(record)">确认收货</a-button>
            </a-popconfirm>
            <a-popconfirm
              title="是否删除订单"
              ok-text="是的"
              cancel-text="取消"
              @confirm="handleDeleteOrder(record)"
              v-if="record.orderStatus == 7"
              v-perms="'delete'"
            >
              <a-button type="link">删除订单</a-button>
            </a-popconfirm>
            <a-button
              type="link"
              @click="getItem(record, '查看物流')"
              v-if="record.orderStatus == 6 && record.expressNo"
              v-perms="'flow'"
            >查看物流</a-button
            >
            <a-button
              type="link"
              @click="handleOpen(record.enterpriseContract)"
              v-if="record.orderStatus > 1"
              v-perms="'look'"
            >查看合同</a-button
            >
            <a-button type="link" @click="$refs.paymentVoucher.$init(record.orderId)" v-if="record.orderStatus == 4" v-perms="'upload'">上传打款凭证</a-button>
            <a-button type="link" @click="$refs.paymentInfo.$init()" v-if="record.orderStatus == 4" v-perms="'message'">付款账号信息</a-button>
            <a-button
              type="link"
              @click="handleUplate(record)"
              v-if="record.orderStatus == 7 && record.status == 0"
              v-perms="'sppj'"
            >商品评价</a-button
            >
            <a-button
              type="link"
              @click="handleUplate(record)"
              v-if="record.orderStatus == 7 && record.status == 1"
              v-perms="'ckpj'"
            >查看评价</a-button
            >
            <a-tooltip placement="left" overlayClassName="tips-class">
              <template slot="title">
                <div class="tips-content">
                  <div class="tips-title">采购步骤：</div>
                  <div class="tips-item">①.采购人订单提交：选择商品规格、型号、数量后，提交订单。</div>
                  <div class="tips-item">
                    ②.供应商响应：供应商收到订单信息，电话联系沟通采购人并沟通最终价格及物流费用后，确认订单。
                  </div>
                  <div class="tips-item">
                    ③.采购人签约：供应商确认订单后，可在控制台-物资采购-订单管理中进行签约合同，确认合同无误后手动签章并确认签署，输入绑定的手机号验证码，合同正式生效。
                  </div>
                  <div class="tips-item">④.供应商签约：采购人签约合同后，供应商进行签约。</div>
                  <div class="tips-item">⑤.采购人打款：供应商签约后，采购人向平台进行线下打款，并上传打款凭证。</div>
                  <div class="tips-item">⑥.平台确认打款：采购人打款由平台确认金额无误后，打款至供应商。</div>
                  <div class="tips-item">
                    ⑦.供应商发货：平台打款至供应商后，供应商可进行发货。发货后，采购人可在物流信息中对应物流单号查看物流信息。
                  </div>
                  <div class="tips-item">⑧.采购人确认收货：采购人确认收货无误后，完成订单。</div>
                  <div class="tips-item">⑨.采购人评论：采购人可对已完成订单进行评价操作。</div>
                </div>
              </template>
              <a-button type="link">采购步骤<a-icon style="margin-left: 4px" type="question-circle" /></a-button>
            </a-tooltip>
            <!--<a-button type="link" @click="visible = true">-->
            <!--订单评价-->
            <!--</a-button>-->
          </template>
        </p-table>
      </div>
    </div>

    <a-modal title="查看物流" :visible="visibleSignContract" @ok="handleCancel" @cancel="handleCancel">
      <a-row>
        <a-col :span="20">
          <div class="order_text">{{ obj.expressNo }}</div>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">确定</a-button>
      </template>
    </a-modal>

    <a-modal title="交易评价" :visible="visiblevaluate" @ok="handleEvaluate" @cancel="handleCancel">
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        labelAlign="left"
        :model="evaluate"
        :rules="rules"
        ref="evaluate"
      >
        <a-row>
          <a-col :span="6">
            <h3>商品总体评价：</h3>
          </a-col>
          <a-col :span="1"></a-col>
          <a-col :span="17">
            <a-form-model-item label="商家态度：" prop="score1">
              <a-rate v-model="evaluate.score1" :disabled="!edit" :tooltips="desc" class="order_evaluate_rate" />
              <span class="ant-rate-text">{{ desc[evaluate.score1 - 1] }}</span>
              <br />
            </a-form-model-item>
            <a-form-model-item label="产品描述：" prop="score2">
              <a-rate v-model="evaluate.score2" :disabled="!edit" :tooltips="desc" class="order_evaluate_rate" />
              <span class="ant-rate-text">{{ desc[evaluate.score2 - 1] }}</span>
              <br />
            </a-form-model-item>
            <a-form-model-item label="物流速度：" prop="score3">
              <a-rate v-model="evaluate.score3" :disabled="!edit" :tooltips="desc" class="order_evaluate_rate" />
              <span class="ant-rate-text">{{ desc[evaluate.score3 - 1] }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-divider />
        <div class="evaluate_title">对本次购买商品的标签评价</div>
        <a-row :gutter="6" v-if="edit">
          <a-col :span="4" v-for="item in typeList" :key="item.id">
            <div
              :class="{
                evaluate_tag: true,
                actived: typeSelect.some((key) => key === item.id),
              }"
              @click="handleTypeClick(item.id)"
            >
              {{ item.label }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="6" v-if="!edit">
          <a-col :span="4" v-for="item in typeList1" :key="item">
            <div class="evaluate_tag">{{ item }}</div>
          </a-col>
        </a-row>
        <a-divider />
        <a-form-model-item label="商品图片评价" prop>
          <a-upload
            list-type="picture-card"
            :file-list="evaluate.picList"
            @change="handleChange"
            :customRequest="uploadEvaluate"
            :disabled="!edit"
            accept=".png, .jpg"
          >
            <div v-if="evaluate.picList.length < 4">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="商品文本评价">
          <a-textarea placeholder="请输入" :disabled="!edit" allow-clear v-model="evaluate.content" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="签章验证"
      cancelText="取消"
      okText="验证签约"
      :visible="verification"
      width="800px"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-row type="flex" justify="center">
        <a-col :span="14" style="margin-bottom: 15px">
          <a-row :gutter="10">
            <a-col :span="5">
              <div class="order_text">验证手机</div>
            </a-col>
            <a-col :span="12">
              <a-input placeholder="Basic usage" />
            </a-col>
            <a-col :span="6">
              <a-button type="link">获取验证码</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="14">
          <a-row :gutter="10">
            <a-col :span="5">
              <div class="order_text">验证码</div>
            </a-col>
            <a-col :span="12">
              <a-input placeholder="短信验证码" />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 合同生成 -->
    <fileDialog ref="fileDialog" :callback="handleUplateStatus" />
    <paymentVoucher @success="$refs.table.refresh()" ref="paymentVoucher" />
    <paymentInfo ref="paymentInfo" />
  </div>
</template>

<script>
import {
  employmentEvaluateDetail,
  employmentEvaluateModfly,
  employmentEvaluatequeryDone,
  employmentOrderUpdate,
  deleteOrder,
  OrderList,
  OrderListDelete,
  uploadImage,
  download,
  getTypeDetail
} from '@/api/materialShop'
import { getDictByNames } from '@/api/dict'

import { dateFormat } from '@/common/common'
import { createAndPreviewEnterprisePurchaseContract, signEnterprisePurchaseContract } from '@/api/contract'
import fileDialog from '@/components/common/pdf-dialog/file-dialog.vue'
import { customModal } from '@/components/common/custom-dialog'
import moment from 'moment'
import paymentVoucher from './payment-voucher.vue'
import paymentInfo from './payment-info.vue'

function formatTime (t) {
  return t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : ''
}

const arrColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    customRender: (t, r, i) => i + 1
  },
  {
    title: '订单编号',
    dataIndex: 'orderNo',
    key: 'orderNo',
    download: true,
    width: 200,
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '商品名称',
    download: true,
    dataIndex: 'productName'
  },
  {
    title: '供应商名称',
    download: true,
    dataIndex: 'supplierName'
  },
  {
    title: '规格型号',
    download: true,
    dataIndex: 'model'
  },
  {
    title: '计量单位',
    download: true,
    dataIndex: 'unit'
  },
  {
    title: '数量',
    download: true,
    dataIndex: 'num'
  },
  {
    title: '订单完成时间',
    dataIndex: 'commentTime',
    key: 'commentTime',
    download: true,
    customRender: (t) => formatTime(t)
  },
  {
    title: '操作',
    width: 300,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

const tabList = [
  {
    label: '全部订单',
    value: ''
  },
  {
    label: '待签约',
    value: '1'
  },
  {
    label: '待付款',
    value: '4'
  },
  {
    label: '待发货',
    value: '5'
  },
  {
    label: '待收货',
    value: '6'
  },
  {
    label: '已完成',
    value: '7'
  }
]

export default {
  name: 'Index',
  components: {
    fileDialog,
    paymentVoucher,
    paymentInfo
  },
  data () {
    return {
      // 查看
      typeList1: [],
      // 修改
      typeList: [],
      typeSelect: [],
      previewVisible: false,
      previewImage: '',
      obj: {},
      visibleSignContract: false,
      visible: false,
      verification: false,
      visiblevaluate: false,
      tableData: [],
      // columns,
      value: 5,
      // rowSelection: {
      //   onSelectAll: this.onSelectAll,
      //   onChange: this.onChange,
      //   onSelect: this.onSelect
      // },
      loading: false,
      expandedRowKeys: [],
      status: '',
      evaluate: {
        score1: 5,
        score2: 5,
        score3: 5,
        typeSelect: [],
        picList: [],
        content: null
      },
      startTime: '',
      orderNo: '',
      supplierName: '',
      endTime: '',
      date: [],
      imageUrl: '',
      tabList,
      statusList: [],
      rules: {
        score1: [
          { required: true, message: '请选择商家服务评价', trigger: 'change' },
          { validator: this.validateScore1, trigger: 'change' }
        ],
        score2: [
          { required: true, message: '请选择产品描述评价', trigger: 'change' },
          { validator: this.validateScore2, trigger: 'change' }
        ],
        score3: [
          { required: true, message: '请选择物流速度评价', trigger: 'change' },
          { validator: this.validateScore3, trigger: 'change' }
        ],
        content: [{ required: true, message: '请输入评价', trigger: 'blur' }]
      },
      edit: false,
      contractInfo: null,
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          download: true,
          width: 200,
          key: 'orderNo',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '商品总额（元）',
          dataIndex: 'totalAmount',
          download: true,
          key: 'totalAmount'
        },
        {
          title: '订单总额（元）',
          dataIndex: 'amount',
          download: true,
          key: 'amount'
        },
        {
          title: '供应商名称',
          dataIndex: 'supplierName',
          download: true,
          key: 'supplierName',
          ellipsis: true
        },
        {
          title: '收货地址',
          dataIndex: 'shippingAddress',
          download: true,
          key: 'shippingAddress',
          ellipsis: true
        },
        {
          title: '收货人',
          dataIndex: 'consignee',
          download: true,
          key: 'consignee',
          ellipsis: true
        },

        {
          title: '订单生成时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          download: true,
          ellipsis: true,
          customRender: (t) => formatTime(t)
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          download: true,
          dataIndex: 'orderStatus',
          customRender: (t) => this.formatStatus(t)
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 300,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  computed: {
    columns () {
      if (this.status === '7') {
        return arrColumns
      }
      return this.tableColumns
    }
  },
  mounted () {
    this.handleGetTypeList()
    getDictByNames('product_order_status').then((res) => {
      this.statusList = res.data[0].detail
    })
    // this.init()
    // this.handleExport()
  },
  methods: {
    formatStatus (value) {
      const data = this.statusList.find((item) => item.value == value) || {}
      return data.label
    },
    handleTypeClick (key) {
      if (this.typeSelect.length < 6) {
        if (this.typeSelect.includes(key)) {
          this.typeSelect = this.typeSelect.filter((item) => item !== key)
        } else {
          this.typeSelect.push(key)
        }
      } else {
        if (this.typeSelect.includes(key)) {
          this.typeSelect = this.typeSelect.filter((item) => item !== key)
        }
        if (this.typeSelect.length == 6) {
          this.$message.error('标签最多可选6个')
        }
      }
    },
    handleOpenPage () {
      window.open('/supplies/materialData')
    },
    handleGetTypeList () {
      getTypeDetail({ dictName: 'commodity_evaluation_tab' }).then((res) => {
        this.typeList = res.data
      })
    },
    validateScore1 (rule, value, callback) {
      if (value !== 0 && !!value) {
        callback()
      } else {
        callback(new Error('请选择商家服务评价'))
      }
    },
    validateScore2 (rule, value, callback) {
      if (value !== 0 && !!value) {
        callback()
      } else {
        callback(new Error('请选择产品描述评价'))
      }
    },
    validateScore3 (rule, value, callback) {
      if (value !== 0 && !!value) {
        callback()
      } else {
        callback(new Error('请选择物流速度评价'))
      }
    },
    handleExport () {
      download().then((res) => {
        console.log(res)
        const BLOB = res
        // FileReader 对象允许Web应用程序异步读取存储在用户计算机上的文件的内容
        const fileReader = new FileReader()
        // 开始读取指定的Blob中的内容。读取完成result属性中将包含一个data: URL格式的Base64字符串以表示所读取文件的内容
        fileReader.readAsDataURL(BLOB)
        // 处理load事件，该事件在读取操作完成时触发
        fileReader.onload = (event) => {
          const a = document.createElement('a')
          a.download = `${name}.xls`
          a.href = event.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    getTableData (params) {
      const getDataFun = this.status === '7' ? employmentEvaluatequeryDone : OrderList
      return getDataFun({
        ...this.handleGetQuery(),
        ...params
      })
    },
    handleEvaluate () {
      if (this.edit) {
        this.$refs.evaluate.validate((valid) => {
          if (valid) {
            const typeList = this.typeList
              .filter((item) => this.typeSelect.some((key) => item.id === key))
              .map((item) => item.label)
            if (typeList.length === 0) {
              this.$message.error('请对本次购买的商品进行标签评价')
            } else {
              employmentEvaluateModfly({
                ...this.evaluate,
                tab: typeList.join(','),
                picList: this.evaluate.picList.map((item) => item.url)
              })
                .then((res) => {
                  this.$message.success('提交成功')
                  this.visiblevaluate = false
                  this.typeSelect = []
                  this.handleCancel()
                  this.$refs.table.refresh()
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        })
      } else {
        this.handleCancel()
      }
    },
    handleChange ({ file, fileList }) {
      this.$set(
        this.evaluate,
        'picList',
        this.evaluate.picList.filter((item) => item.uid !== file.uid)
      )
    },
    uploadEvaluate (file) {
      const form = new FormData()
      form.append('file', file.file)
      uploadImage(form)
        .then((res) => {
          this.evaluate.picList.push({
            uid: this.evaluate.picList.length + 1,
            name: 'image.png',
            status: 'done',
            url: res.path
          })
        })
        .finally((err) => {})
    },
    upload (file) {
      const form = new FormData()
      form.append('file', file.file)
      uploadImage(form).then((res) => {
        this.imageUrl = res.path
      })
    },
    handleUplate (row) {
      employmentEvaluateDetail({ id: row.evaluationId }).then((res) => {
        this.evaluate = {
          ...res.data,
          picList: res.data.picList.map((v, i) => ({
            uid: i + 1,
            name: 'image.png',
            status: 'done',
            url: v
          }))
        }
        // 对接口字段
        if (res.data.tab) {
          const h = res.data.tab.split(',')
          this.typeList1 = h
        } else {
          this.typeList1 = []
        }
        this.edit = row.status == 0
        this.visiblevaluate = true
      })
    },
    getItem (item, val) {
      this.obj = item
      if (item.orderStatus === '4') {
        this.$refs.paymentVoucher.$init(item.orderId)
      } else if (val === '查看物流') {
        this.visibleSignContract = true
      }
    },
    handleDeleteOrder (rowItem) {
      deleteOrder(rowItem.orderId).then((res) => {
        this.$message.success('订单删除成功')
        this.$refs.table.refresh()
      })
    },
    confirm (val) {
      var params = {}
      var url
      if (val === 'cancel') {
        url = OrderListDelete
        params = {
          id: this.obj.orderId
        }
      }
      if (val === 'sure') {
        url = employmentOrderUpdate
        params = {
          status: 7,
          id: this.obj.orderId
        }
      }
      if (val === 'sign') {
        // url = employmentOrderUpdate
        params = {
          amount: this.obj.amount,
          comment: '',
          freight: this.obj.freight,
          contractNumber: 'QYCGHT',
          deliveryTime: this.formatTime(this.obj.deliveryTime, 'YYYY-MM-DD'),
          orderNumber: this.obj.orderNo,
          shippingAddress: this.obj.shippingAddress,
          status: 0,
          enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
          enterpriseName: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseName,
          projectLeaderId: this.obj.purchaserId
        }
        this.$spin.show()
        createAndPreviewEnterprisePurchaseContract(params)
          .then((res) => {
            customModal({
              propsData: {
                title: '采购合同签约',
                visible: true,
                width: 400,
                closable: false,
                cancelText: '未完成签约',
                keyboard: false,
                maskClosable: false,
                okText: '已完成签约'
              },
              on: {
                ok: () => this.$refs.table.refresh()
              },
              component: () => <div>请在新页面内完成企业签约！</div>
            })
            window.open(res.data)
          })
          .finally(() => {
            this.$spin.hide()
          })
      }
      if (url) {
        url(params)
          .then((res) => {
            if (res.success) {
              this.$message.success('操作成功')
              this.$refs.table.refresh()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    handleUplateStatus () {
      return signEnterprisePurchaseContract({
        id: this.contractInfo.id,
        orderNumber: this.obj.orderNo
      })
        .then((res) => {
          this.$refs.fileDialog.$init(res)
        })
        .finally(() => {
          return Promise.resolve()
        })
    },
    cancel (e) {
      this.$message.error('Click on No')
    },
    handleGetQuery () {
      const { orderNo, supplierName, status } = this
      const [startTime, endTime] = this.date || ['', '']
      return {
        startTime,
        orderNo,
        supplierName,
        status,
        endTime
      }
    },
    handleReset () {
      this.orderNo = ''
      this.supplierName = ''
      this.date = ['', '']
    },
    init () {
      this.loading = true
      const params = this.handleGetQuery()
      console.log(params)
      OrderList(params)
        .then((res) => {
          res.data.forEach((v) => {
            v.createdTime = dateFormat(v.createdTime)
          })
          this.loading = false
          this.tableData = res.data
          this.pagination.total = res.totalCount
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getDetail (record) {
      this.$router.push({ path: '/materialDemand/materialDemandOrderDetail', query: { id: record.orderId, totalAmount: record.totalAmount } })
    },
    getRow (page) {
      this.pagination = page
      this.init()
    },
    handleRefresh (value) {
      this.status = value
      this.$refs.table.initPage()
    },
    handleOk () {
      const params = {
        status: 5,
        id: this.obj.orderId,
        paymentVoucher: this.imageUrl
      }
      employmentOrderUpdate(params)
        .then((res) => {
          if (res.success) {
            this.verification = false
            this.$message.success('操作成功')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCancel () {
      this.visible = false
      this.verification = false
      this.visibleSignContract = false
      this.visiblevaluate = false
      this.typeSelect = []
      this.evaluate = {
        score1: 5,
        score2: 5,
        score3: 5,
        typeSelect: [],
        picList: [],
        content: null
      }
      this.previewVisible = false
      this.$refs.form && this.$refs.form.resetFields()
      this.$refs.evaluate && this.$refs.evaluate.resetFields()
    },
    handleOpen (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');
@import url('../../recruitUse/evaluate/index');
.order__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
}
.tips-class {
  max-width: 1080px;
  .tips-title {
    font-weight: 550;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .tips-item {
    margin-bottom: 5px;
  }
}
</style>
